import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OpeningHours } from '@docbay/schemas';

import { uploadedPhoto } from 'applicaiton/store/reducers/Photo/PhotoSlice';
import { Gender } from 'applicaiton/constants/gender';
import {
  getPrivateDoctor,
  updatePrivateDoctor,
} from 'applicaiton/store/reducers/PrivateDoctor/ActionCreators';
import { getUserCountryIso } from 'applicaiton/sessionStorage/auth';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';

import { Input, Loader, PhotoUploader, PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { getSchedule } from 'common/helpers/getSchedule';
import { ContactsType } from 'common/types/clinics';

import {
  PrivateDoctorProfessionalData,
  usePrivateDoctorForm,
} from 'features/feature-clinic-owner-profile/hooks/usePrivateDoctorForm';

import PrivateDoctorProfessionalProfile from '../PrivateDoctorProfessionalProfile';
import SuccessModal from '../SuccessModal';

import { Wrapper, FieldsSection, ButtonsWrapper } from './styles';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { resetIsUpdated } from 'applicaiton/store/reducers/PrivateDoctor/PrivateDoctorSlice';
import { useUserProfile } from '../../../../common/hooks/useUserProfile';

const PrivateDoctorForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const { uploadedPhotos } = useAppSelector((state) => state.photos);
  const { refreshTokenForUser } = useUserProfile();
  const {
    isLoading: isLoadingPrivateDoctor,
    privateDoctor,
    isUpdated,
  } = useAppSelector((state) => state.privateDoctor);

  const {
    register,
    errors,
    watch,
    setValue,
    setError,
    handleSubmit,
    clearErrors,
  } = usePrivateDoctorForm();

  useEffect(() => {
    if (currentClinicOwner?.id) {
      dispatch(getPrivateDoctor(currentClinicOwner.id));
    }
  }, [currentClinicOwner?.id]);

  useEffect(() => {
    if (privateDoctor) {
      setValue('firstName', privateDoctor.firstName || '');
      setValue('lastName', privateDoctor.lastName || '');
      setValue('jobPosition', privateDoctor.jobPosition || '');
      setValue(
        'professionalData.description',
        privateDoctor.professional?.description || '',
      );
      setValue(
        'professionalData.spokenLanguageIds',
        privateDoctor.professional?.professionalSpokenLanguages?.map(
          (item) => item.id,
        ) || [],
      );
      setValue(
        'professionalData.phone',
        privateDoctor.professional?.phone || '',
      );
      setValue(
        'professionalData.dateOfBirth',
        privateDoctor.professional?.dateOfBirth || '',
      );
      setValue(
        'professionalData.gender',
        privateDoctor.professional?.gender || Gender.Male,
      );
      setValue(
        'professionalData.medicalNumber',
        privateDoctor.professional?.medicalNumber || '',
      );
      setValue(
        'professionalData.photos',
        privateDoctor.professional?.photos || [],
      );
      setValue(
        'professionalData.socialMedia',
        privateDoctor.professional?.socialMedia?.length
          ? privateDoctor.professional?.socialMedia
          : [
              {
                name: 'Facebook' as ContactsType,
                url: '',
              },
            ],
      );
      if (privateDoctor.professional?.clinicsRelations?.length) {
        const specializations =
          privateDoctor.professional?.clinicsRelations[0].specializations;
        const specializationToAdd = specializations.length
          ? specializations.map((item) => String(item.specialization.id))
          : [''];

        const countryOfPractice =
          privateDoctor.professional?.clinicsRelations[0].clinic?.address
            ?.supportedCountry?.code || getUserCountryIso();

        setValue(
          'professionalData.clinicsRelation.specializationsToAdd',
          specializationToAdd,
        );
        setValue(
          'professionalData.clinicsRelation.schedule.hours',
          privateDoctor.professional?.clinicsRelations[0].schedule.hours ||
            (getSchedule() as OpeningHours[]),
        );
        setValue('countryOfPracticeIso', countryOfPractice!);
      }

      if (currentClinicOwner?.photo) {
        const photo = {
          key: currentClinicOwner?.photo.key,
          photoUrl: currentClinicOwner.photo.photoUrl,
          thumbnailUrl: currentClinicOwner.photo.thumbnailUrl,
        };
        uploadedPhoto([photo]);
      }
    }
  }, [privateDoctor]);

  function handleSpecializationsChanges(
    professionalData: PrivateDoctorProfessionalData,
  ) {
    const specializationsToAdd =
      professionalData.clinicsRelation.specializationsToAdd.filter((item) => {
        const isExistedItem = (privateDoctor?.professional?.clinicsRelations ||
          [])[0]?.specializations.some(
          (specialization) => specialization.specialization.id === item,
        );
        if (!isExistedItem) return item;
      });

    const specializationsToDelete = (privateDoctor?.professional
      ?.clinicsRelations || [])[0]?.specializations
      ?.filter((specialization) => {
        const isSpecializationExist =
          professionalData.clinicsRelation.specializationsToAdd.some(
            (item) => String(specialization.specialization.id) === String(item),
          );

        if (!isSpecializationExist) {
          return specialization;
        }
      })
      .map((specialization) => String(specialization.specialization.id));
    return { specializationsToAdd, specializationsToDelete };
  }

  function handleSocialMediaChanges(
    professionalData: PrivateDoctorProfessionalData,
  ) {
    const socialMedia = professionalData.socialMedia.filter((item) => {
      const isExistedItem = (
        privateDoctor?.professional?.socialMedia || []
      ).some((elem) => elem.id === item.id);
      if (!isExistedItem && item.url) return item;
    });

    const socialMediaToDelete = (privateDoctor?.professional?.socialMedia || [])
      .filter((item) => {
        const isDeletedItem = (professionalData.socialMedia || []).some(
          (elem) => item.id !== elem.id,
        );
        if (isDeletedItem && item.id) return item;
      })
      .map((item) => String(item.id));
    return { socialMedia, socialMediaToDelete };
  }

  const onSubmit = async () => {
    if (!currentClinicOwner) {
      return;
    }
    const professionalData = watch('professionalData');
    const {
      phone: currentPhone,
      dateOfBirth,
      medicalNumber,
      ...restData
    } = professionalData;

    const { specializationsToAdd, specializationsToDelete } =
      handleSpecializationsChanges(professionalData);
    const { socialMedia, socialMediaToDelete } =
      handleSocialMediaChanges(professionalData);

    const photosToDelete = privateDoctor?.professional?.photos
      ?.filter((photo) => {
        const photoExist = professionalData.photos.some(
          (item) => item.photoUrl === photo.photoUrl,
        );
        if (!photoExist) {
          return photo;
        }
      })
      .map((photo) => photo.id);

    const phone = currentPhone.startsWith('+')
      ? currentPhone
      : `+${currentPhone}`;

    const hours = professionalData.clinicsRelation.schedule.hours.filter(
      (item) => {
        const isWorkingTime = item.slots.some((slot) => !!slot.endTime);
        if (isWorkingTime) return item;
      },
    );

    await dispatch(
      updatePrivateDoctor({
        id: String(currentClinicOwner.id),
        data: {
          firstName: watch('firstName'),
          lastName: watch('lastName'),
          jobPosition: watch('jobPosition'),
          countryOfPracticeIso: watch('countryOfPracticeIso'),
          ...(uploadedPhotos.length ? { photo: uploadedPhotos[0] } : {}),
          professionalData: {
            ...restData,
            ...(dateOfBirth ? { dateOfBirth } : {}),
            ...(medicalNumber ? { medicalNumber } : {}),
            ...(currentPhone.length ? { phone } : {}),
            socialMedia,
            socialMediaToDelete,
            photosToDelete: photosToDelete || [],
            clinicsRelation: {
              ...professionalData.clinicsRelation,
              schedule: {
                hours,
              },
              specializationsToAdd,
              specializationsToDelete,
            },
          },
        },
      }),
    ).then(() => {
      dispatch(uploadedPhoto([]));
      refreshTokenForUser();
    });
  };

  const closeSuccessModal = () => {
    dispatch(resetIsUpdated());
  };

  const isStateChanged = useMemo(() => {
    if (!privateDoctor) return true;

    const {
      firstName,
      lastName,
      jobPosition,
      countryOfPracticeIso,
      professionalData,
    } = watch();

    const specializationsToDelete = (privateDoctor?.professional
      ?.clinicsRelations || [])[0]?.specializations?.filter(
      (specialization) => {
        const isDeletedItem =
          professionalData.clinicsRelation.specializationsToAdd.some(
            (item) => specialization.specialization.id !== item,
          );
        if (isDeletedItem) {
          return specialization;
        }
      },
    );

    if (specializationsToDelete?.length) return true;

    const hours = professionalData.clinicsRelation.schedule.hours
      .filter((item) => {
        const isWorkingTime = item.slots.some((slot) => !!slot.endTime);
        if (isWorkingTime) return item;
      })
      .map((item) => ({
        day: item.day,
        isEnabled: item.isEnabled,
        slots: item.slots,
      }));

    const currentChanges = {
      firstName,
      lastName,
      jobPosition,
      countryOfPracticeIso,
      professionalData: {
        description: professionalData.description,
        spokenLanguageIds: professionalData.spokenLanguageIds,
        phone: professionalData.phone,
        dateOfBirth: professionalData.dateOfBirth,
        gender: professionalData.gender,
        medicalNumber: professionalData.medicalNumber,
        photos: professionalData.photos,
        socialMedia: professionalData.socialMedia,
        clinicsRelation: {
          specializationsToAdd:
            professionalData.clinicsRelation.specializationsToAdd,
          schedule: {
            hours,
          },
        },
      },
    };

    const defaultData = {
      firstName: privateDoctor.firstName,
      lastName: privateDoctor.lastName,
      jobPosition: privateDoctor.jobPosition,
      countryOfPracticeIso:
        (privateDoctor.professional?.clinicsRelations || [])[0]?.clinic?.address
          ?.supportedCountry?.code || getUserCountryIso(),
      professionalData: {
        description: privateDoctor.professional?.description,
        spokenLanguageIds:
          privateDoctor.professional?.professionalSpokenLanguages?.map(
            (item) => item.id,
          ),
        phone: privateDoctor.professional?.phone,
        dateOfBirth: privateDoctor.professional?.dateOfBirth,
        gender: privateDoctor.professional?.gender,
        medicalNumber: privateDoctor.professional?.medicalNumber,
        photos: privateDoctor.professional?.photos,
        socialMedia: privateDoctor.professional?.socialMedia,
        clinicsRelation: {
          specializationsToAdd: (privateDoctor.professional?.clinicsRelations ||
            [])[0]?.specializations.map((item) => item.specialization.id),
          schedule: {
            hours: (privateDoctor.professional?.clinicsRelations ||
              [])[0]?.schedule.hours.map((item) => ({
              day: item.day,
              isEnabled: item.isEnabled,
              slots: item.slots,
            })),
          },
        },
      },
    };

    const hasChanges = compareObjectsChanges(currentChanges, defaultData, true);

    return hasChanges;
  }, [privateDoctor, watch()]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const saveDisabled = useMemo(() => {
    return !isStateChanged || isLoading;
  }, [isStateChanged, isLoading]);

  return (
    <Wrapper>
      {(isLoading || isLoadingPrivateDoctor) && <Loader />}
      <div>
        <FieldsSection>
          <Input
            label={t('personal_information.first_name') || ''}
            id={'firstName'}
            type="text"
            register={register}
            errorMessage={errors?.firstName?.message}
            placeholder={t('patient.new_patient_name') || ''}
          />
          <Input
            label={t('personal_information.last_name') || ''}
            id={'lastName'}
            type="text"
            register={register}
            errorMessage={errors?.lastName?.message}
            placeholder={t('patient.new_patient_lastname') || ''}
          />
          <Input
            label={t('personal_information.job_position') || ''}
            id={'jobPosition'}
            type="text"
            register={register}
            placeholder={
              t('personal_information.job_position_placeholder') || ''
            }
          />
          <PhotoUploader defaultPhoto={currentClinicOwner?.photo} />
        </FieldsSection>

        <PrivateDoctorProfessionalProfile
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
        />
      </div>
      <ButtonsWrapper>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={saveDisabled}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonsWrapper>
      {isUpdated && (
        <SuccessModal
          name={`${currentClinicOwner?.firstName} ${currentClinicOwner?.lastName}`}
          onClose={closeSuccessModal}
        />
      )}
    </Wrapper>
  );
};

export default PrivateDoctorForm;
