import {
  UserLoginResDto,
  UserLoginDto,
  UserRefreshTokenDto,
  UserSetPasswordDto,
  UserRefreshTokenResDto,
  UserChangePasswordDto,
  UserChangeEmailDto,
  UserRequestChangePhoneDto,
  UserChangePhoneDto,
  UserVerifyCodeDto,
} from '@docbay/schemas';
import axiosInstance from './axiosInstance';
import axiosTokenInstance from './axiosTokenInstance';
import axiosAuthInstance from './axiosAuthInstance';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { ForgotPasswordDto } from 'common/auth/forgotPassword/types';

export const UsersAPI = {
  usersLogin: (data: UserLoginDto) =>
    axiosTokenInstance().post<UserLoginResDto>('/users/login', data),
  usersRefreshToken: (data: UserRefreshTokenDto) =>
    axiosTokenInstance().post<UserRefreshTokenResDto>(
      '/users/refresh-token',
      data,
    ),
  usersForgotPassword: (data: ForgotPasswordDto) => {
    const { captchaToken, ...restData } = data;
    return axiosAuthInstance('').post('/users/forgot-password', restData, {
      headers: {
        ...(captchaToken ? { recaptcha: captchaToken } : {}),
      },
    });
  },
  usersVerifyCode: (data: UserVerifyCodeDto) => {
    const language = getLanguageFromLocalStorage();
    return axiosAuthInstance('').post('/users/verify-code', {
      ...data,
      language,
    });
  },
  usersCreateNewPassword: (data: UserSetPasswordDto) =>
    axiosTokenInstance().post('/users/password', data),
  userChangePassword: (data: UserChangePasswordDto, token: string) => {
    return axiosAuthInstance(token).post('/users/change-password', data);
  },
  userVerifyOldEmail: (
    params: { email: string; language: string },
    token: string,
  ) =>
    axiosAuthInstance(token).post(
      '/users/email/verification-code/request',
      params,
    ),
  userSetNewEmail: (params: UserChangeEmailDto, token: string) =>
    axiosAuthInstance(token).patch('/users/email', params),
  userPhoneVerificationCode: (data: UserRequestChangePhoneDto, token: string) =>
    axiosAuthInstance(token).post(
      '/users/phone/verification-code/request',
      data,
    ),
  userSetNewPhone: (params: UserChangePhoneDto, token: string) =>
    axiosAuthInstance(token).patch('/users/phone', params),
  signOut: () => axiosInstance.post('/users/logout'),
  checkUserEmailExisting: (email: string) =>
    axiosInstance.post<boolean>('/users/email', { email }),
  setUserLanguage: (languageIso: string) =>
    axiosInstance.post('/users/change-language', { languageIso }),
};
