import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { styledTheme } from 'applicaiton/theme';
import { Link } from 'react-router-dom';

interface CheckboxError {
  error: number;
}

export const ForgotPasswordWrapper = styled(Box)`
  padding: 64px 0 0;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
  gap: 32px;
`;

export const StyledForm = styled('form')`
  width: 100%;
`;

export const FormGroupStyled = styled(Box)`
  margin-bottom: 28px;
`;

export const StyledBlueLink = styled(Link)`
  color: ${styledTheme.color.blue800};
  font: 700 16px/22.4px 'Mulish', sans-serif;
  text-decoration: none;
  letter-spacing: 0.08px;
  cursor: pointer;
`;

export const FormSubTitle = styled(Box)`
  color: ${styledTheme.color.grey700};
  text-align: center;
  font: 500 18px/25.2px 'Mulish', sans-serif;
  letter-spacing: 0.09px;
  margin-top: 12px;
`;

export const FormBlueSubTitle = styled('span')`
  color: ${styledTheme.color.blue700};
  font: 700 18px/25.2px 'Mulish', sans-serif;
  letter-spacing: 0.09px;
`;

export const UseOtherEmailWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const UseOtherEmailTilte = styled(Typography)`
  font: 400 16px/22.4px 'Mulish', sans-serif;
  color: ${styledTheme.color.grey800};
  margin-bottom: 4px;
`;

export const ResendCodeWrapper = styled(Box)`
  display: inline-flex;
`;

export const ReCaptchaStyled = styled('div')<CheckboxError>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: ${({ error }) => (error ? styledTheme.color.error2 : '')};
  }
`;
